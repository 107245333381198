<template>
  <b-modal
    v-model="isVisible"
    v-b-modal.modal-lg
    no-close-on-backdrop
    title="File Mapping"
    ok-title="Import"
    cancel-variant="outline-secondary"
    body-class="tw-p-0"
    @ok="onOkClick"
    @cancel="onCancelClick"
  >
    <DxScrollView
      :height="modalHeight"
    >
      <div class="tw-flex tw-flex-col tw-pr-8 tw-pl-8 tw-pt-4 tw-pb-4">
        <h5 class="border-bottom pb-50 mb-1">
          File Details
        </h5>
        <div class="tw-flex tw-flex-col tw-gap-1 mapping-wrapper">
          <div class="d-flex flex-row align-items-baseline">
            <div class="pr-1 label">
              Currency:
            </div>
            <div class="flex-1 tw-p-1">
              USD
            </div>
          </div>
        </div>
        <hr class="tw-mb-5 tw-mt-5">
        <div class="tw-flex tw-flex-col tw-gap-2 mapping-wrapper">
          <div class="tw-flex tw-flex-col tw-gap-2">
            <div class="tw-flex tw-flex-row tw-items-center">
              <div class="pr-1 label">
                Marketplace:
              </div>
              <div class="flex-1">
                <v-select
                    v-model="fileMapping.marketplace"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Select"
                    :options="mappingOptions"
                    :clearable="false"
                />
              </div>
            </div>
            <div class="tw-flex tw-flex-row tw-items-center">
              <div class="pr-1 label">
                ASIN:
              </div>
              <div class="flex-1">
                <v-select
                    v-model="fileMapping.asin"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Select"
                    :options="mappingOptions"
                    :clearable="false"
                />
              </div>
            </div>
            <div class="tw-flex tw-flex-row tw-items-center">
              <div class="pr-1 label">
                SKU:
              </div>
              <div class="flex-1">
                <v-select
                    v-model="fileMapping.sku"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Select"
                    :options="mappingOptions"
                    :clearable="false"
                />
              </div>
            </div>
            <div class="tw-flex tw-flex-row tw-items-center">
              <div class="pr-1 label">
                Start Date:
              </div>
              <div class="flex-1">
                <v-select
                    v-model="fileMapping.startDate"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Select"
                    :options="mappingOptions"
                    :clearable="false"
                />
              </div>
            </div>
            <div class="tw-flex tw-flex-row tw-items-center">
              <div class="pr-1 label">
                End Date:
              </div>
              <div class="flex-1">
                <v-select
                    v-model="fileMapping.endDate"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Select"
                    :options="mappingOptions"
                    :clearable="false"
                />
              </div>
            </div>
          </div>
          <div
              v-if="isAdvancedView"
              class="tw-flex tw-flex-col tw-gap-2"
          >
            <div class="d-flex flex-row align-items-center">
              <div class="pr-1 label">
                Manufacturing
              </div>
              <div class="flex-1">
                <v-select
                    v-model="fileMapping.manufacturing"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Select"
                    :options="mappingOptions"
                    :clearable="false"
                />
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div class="pr-1 label">
                Inspection
              </div>
              <div class="flex-1">
                <v-select
                    v-model="fileMapping.inspection"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Select"
                    :options="mappingOptions"
                    :clearable="false"
                />
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div class="pr-1 label">
                Region Shipping
              </div>
              <div class="flex-1">
                <v-select
                    v-model="fileMapping.regionShipping"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Select"
                    :options="mappingOptions"
                    :clearable="false"
                />
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div class="pr-1 label">
                Shipping to Amz
              </div>
              <div class="flex-1">
                <v-select
                    v-model="fileMapping.localShippingAmount"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Select"
                    :options="mappingOptions"
                    :clearable="false"
                />
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div class="pr-1 label">
                Import Tax
              </div>
              <div class="flex-1">
                <v-select
                    v-model="fileMapping.importTax"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Select"
                    :options="mappingOptions"
                    :clearable="false"
                />
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div class="pr-1 label">
                Other Product Costs
              </div>
              <div class="flex-1">
                <v-select
                    v-model="fileMapping.otherCosts"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Select"
                    :options="mappingOptions"
                    :clearable="false"
                />
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div class="pr-1 label">
                FBM Shipping Per Unit
              </div>
              <div class="flex-1">
                <v-select
                    v-model="fileMapping.fbmShippingPerUnit"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Select"
                    :options="mappingOptions"
                    :clearable="false"
                />
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div class="pr-1 label">
                Sales/VAT tax
              </div>
              <div class="flex-1">
                <v-select
                    v-model="fileMapping.salesVatTax"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Select"
                    :options="mappingOptions"
                    :clearable="false"
                />
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div class="pr-1 label">
                Offset VAT on fees
              </div>
              <div class="flex-1">
                <v-select
                    v-model="fileMapping.offsetVatOnFees"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Select"
                    :options="mappingOptions"
                    :clearable="false"
                />
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div class="pr-1 label">
                Brand
              </div>
              <div class="flex-1">
                <v-select
                    v-model="fileMapping.brand"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Select"
                    :options="mappingOptions"
                    :clearable="false"
                />
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div class="pr-1 label">
                Supplier
              </div>
              <div class="flex-1">
                <v-select
                    v-model="fileMapping.supplier"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Select"
                    :options="mappingOptions"
                    :clearable="false"
                />
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div class="pr-1 label">
                <div>Tags</div>
                <small class="text-warning">(Comma separated value)</small>
              </div>
              <div class="flex-1">
                <v-select
                    v-model="fileMapping.tags"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Select"
                    :options="mappingOptions"
                    :clearable="false"
                />
              </div>
            </div>
          </div>
          <div v-else>
            <div class="d-flex flex-row align-items-center">
              <div class="pr-1 label">
                <div>Total Cost</div>
              </div>
              <div class="flex-1">
                <v-select
                    v-model="fileMapping.totalCost"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Select"
                    :options="mappingOptions"
                    :clearable="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DxScrollView>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { DxScrollView } from 'devextreme-vue/scroll-view'

const defaultMapping = {
  marketplace: undefined,
  asin: undefined,
  sku: undefined,
  manufacturing: undefined,
  inspection: undefined,
  regionShipping: undefined,
  importTax: undefined,
  otherCosts: undefined,
  localShippingAmount: undefined,
  salesVatTax: undefined,
  offsetVatOnFees: undefined,
  brand: undefined,
  supplier: undefined,
  tags: undefined,
  totalCost: undefined,
  fbmShippingPerUnit: undefined,
  startDate: undefined,
  endDate: undefined,
}

export default {
  name: 'FileMappingModal',
  components: {
    DxScrollView,
    BModal,
    vSelect,
  },
  props: {
    visible: {
      type: Boolean,
    },
    mappingOptions: {
      type: Array,
      default: () => ([]),
    },
    advancedView: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const defaultFileMapping = JSON.parse(JSON.stringify(defaultMapping))
    return {
      isAdvancedView: true,
      currencyOptions: [
        {
          id: 'USD',
          title: 'USD',
        },
        {
          id: 'CAD',
          title: 'CAD',
        },
        {
          id: 'MXN',
          title: 'MXN',
        },
        {
          id: 'BRL',
          title: 'BRL',
        },
        {
          id: 'GBP',
          title: 'GBP',
        },
        {
          id: 'EUR',
          title: 'EUR',
        },
        {
          id: 'PLN',
          title: 'PLN',
        },
        {
          id: 'SEK',
          title: 'SEK',
        },
        {
          id: 'EGP',
          title: 'EGP',
        },
        {
          id: 'AED',
          title: 'AED',
        },
        {
          id: 'SAR',
          title: 'SAR',
        },
        {
          id: 'TRY',
          title: 'TRY',
        },
        {
          id: 'INR',
          title: 'INR',
        },
        {
          id: 'JPY',
          title: 'JPY',
        },
        {
          id: 'AUD',
          title: 'AUD',
        },
        {
          id: 'SGD',
          title: 'SGD',
        },
      ],
      currency: {
        id: 'USD',
        title: 'USD',
      },
      fileMapping: defaultFileMapping,
    }
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    modalHeight() {
      return this.currentBreakPoint === 'xs' ? '80vh' : '75vh'
    },
    isVisible: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.resetModal()
        }
        this.$emit('input', value)
      },
    },
  },
  watch: {
    mappingOptions() {
      this.populateMapping()
    },
    advancedView(value) {
      this.isAdvancedView = value
    },
  },
  mounted() {
    this.isAdvancedView = this.advancedView
  },
  methods: {
    onOkClick(bvModalEvt) {
      bvModalEvt.preventDefault()
      let mapping
      if (this.isAdvancedView) {
        mapping = this.getAdvancedMapping()
      } else {
        mapping = this.getSimpleMapping()
      }
      const payload = {
        currency: this.currency.id || undefined,
        mapping,
      }
      this.$emit('process-file', payload)
    },
    getSimpleMapping() {
      return {
        asin: this.fileMapping.asin?.id ?? undefined,
        sku: this.fileMapping.sku?.id ?? undefined,
        marketplace: this.fileMapping.marketplace?.id ?? undefined,
        total_cost: this.fileMapping.totalCost?.id ?? undefined,
      }
    },
    getAdvancedMapping() {
      return {
        asin: this.fileMapping.asin?.id ?? undefined,
        sku: this.fileMapping.sku?.id ?? undefined,
        marketplace: this.fileMapping.marketplace?.id ?? undefined,
        manufacturing: this.fileMapping.manufacturing?.id ?? undefined,
        inspection: this.fileMapping.inspection?.id ?? undefined,
        region_shipping: this.fileMapping.regionShipping?.id ?? undefined,
        local_shipping_amount: this.fileMapping.localShippingAmount?.id ?? undefined,
        import_tax: this.fileMapping.importTax?.id ?? undefined,
        other_costs: this.fileMapping.otherCosts?.id ?? undefined,
        sales_vat_tax: this.fileMapping.salesVatTax?.id ?? undefined,
        offset_vat_on_fees: this.fileMapping.offsetVatOnFees?.id ?? undefined,
        fbm_shipping_per_unit: this.fileMapping.fbmShippingPerUnit?.id ?? undefined,
        brand: this.fileMapping.brand?.id ?? undefined,
        supplier: this.fileMapping.supplier?.id ?? undefined,
        tags: this.fileMapping.tags?.id ?? undefined,
        start_date: this.fileMapping.startDate?.id ?? undefined,
        end_date: this.fileMapping.endDate?.id ?? undefined,
      }
    },
    onCancelClick() {
      this.resetModal()
      this.$emit('cancel')
    },
    populateMapping() {
      this.fileMapping.marketplace = this.mappingOptions.find(value => value.id.toLowerCase().indexOf('marketplace') !== -1)
      this.fileMapping.asin = this.mappingOptions.find(value => value.id.toLowerCase().indexOf('asin') !== -1)
      this.fileMapping.sku = this.mappingOptions.find(value => value.id.toLowerCase().indexOf('sku') !== -1)
      this.fileMapping.manufacturing = this.mappingOptions.find(value => value.id.toLowerCase().indexOf('manufacturing') !== -1)
      this.fileMapping.inspection = this.mappingOptions.find(value => value.id.toLowerCase().indexOf('inspection') !== -1)
      this.fileMapping.regionShipping = this.mappingOptions.find(value => value.id.toLowerCase().indexOf('region') !== -1)
      this.fileMapping.localShippingAmount = this.mappingOptions.find(value => value.id.toLowerCase().indexOf('local') !== -1 || value.id.toLowerCase().indexOf('amz') !== -1 || value.id.toLowerCase().indexOf('amazon') !== -1)
      this.fileMapping.importTax = this.mappingOptions.find(value => value.id.toLowerCase().indexOf('import') !== -1)
      this.fileMapping.otherCosts = this.mappingOptions.find(value => value.id.toLowerCase().indexOf('other') !== -1)
      this.fileMapping.totalCost = this.mappingOptions.find(value => value.id.toLowerCase().indexOf('total_cost') !== -1 || value.id.toLowerCase().indexOf('total') !== -1 || value.id.toLowerCase().indexOf('Total Cost') !== -1 || value.id.toLowerCase().indexOf('total cost') !== -1)
      this.fileMapping.fbmShippingPerUnit = this.mappingOptions.find(value => value.id.toLowerCase().indexOf('fbm') !== -1)
      this.fileMapping.salesVatTax = this.mappingOptions.find(value => value.id.toLowerCase().indexOf('vat') !== -1)
      this.fileMapping.offsetVatOnFees = this.mappingOptions.find(value => value.id.toLowerCase().indexOf('offset') !== -1)
      this.fileMapping.brand = this.mappingOptions.find(value => value.id.toLowerCase().indexOf('brand') !== -1)
      this.fileMapping.supplier = this.mappingOptions.find(value => value.id.toLowerCase().indexOf('supplier') !== -1)
      this.fileMapping.tags = this.mappingOptions.find(value => value.id.toLowerCase().indexOf('tags') !== -1)
      this.fileMapping.startDate = this.mappingOptions.find(value => value.id.toLowerCase().indexOf('start_date') !== -1) || this.mappingOptions.find(value => value.id.toLowerCase().indexOf('start') !== -1)
      this.fileMapping.endDate = this.mappingOptions.find(value => value.id.toLowerCase().indexOf('end_date') !== -1) || this.mappingOptions.find(value => value.id.toLowerCase().indexOf('end') !== -1)
    },
    resetModal() {
      this.fileMapping = JSON.parse(JSON.stringify(defaultMapping))
    },
  },
}
</script>
<style lang="scss" scoped>
.mapping-wrapper {
  .label {
    min-width: 180px;
    max-width: 180px;
  }
}
</style>
