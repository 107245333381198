<template>
  <div>
    <DxButton
      id="filter-builder-button"
      icon="filter"
      type="default"
      :styling-mode="isFilterApplied"
      :text="buttonText"
      :width="buttonWidth"
      @click="onFilterBuilderButtonClick"
    />
    <DxPopup
      id="filter-builder"
      :width="popupWidth"
      :height="popupHeight"
      :visible.sync="isFilterBuilderVisible"
      :show-title="false"
      :hide-on-outside-click="true"
    >
      <DxPosition
        of="#filter-builder-button"
        :at="popupPosition.at"
        :my="popupPosition.my"
        :offset="popupPosition.offset"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="before"
        :options="templatesBtnOptions"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="clearFiltersButtonOptions"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="applyFiltersButtonOptions"
      />
      <template #content>
        <DxScrollView
          width="100%"
          style="max-height: 500px"
        >
          <DxFilterBuilder
            ref="dxFilterBuilder"
            :value.sync="filters"
            :fields="filterBuilderFields"
            :group-operations="['and', 'or']"
            :max-group-level="1"
            :on-value-changed="onFilterBuilderValueChange"
          />
        </DxScrollView>
      </template>
    </DxPopup>
    <DxPopover
      :width="300"
      :visible.sync="isPopoverVisible"
      :wrapper-attr="{ id: 'filter-template' }"
      target="#filter-templates-btn"
      position="bottom left"
    >
      <DxPosition
        of="#filter-templates-btn"
        :at="popoverPosition.at"
        :my="popoverPosition.my"
      />
      <MyFilters
        :data-source="filterTemplates"
        @on-filter-template-click="onFilterTemplateClick"
        @on-filter-template-delete="onFilterTemplateDelete"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="before"
        :options="saveFilterBtnOptions"
      />
    </DxPopover>
    <DxPopup
      :wrapper-attr="{ id: 'save-filter-template' }"
      :width="savingFilterPopupWidth"
      :height="200"
      :visible.sync="isSavingFilterPopupVisible"
      :show-title="true"
      shading-color="rgb(34 41 47 / 50%)"
      title="Save Personal Filter"
      :hide-on-outside-click="false"
    >
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="cancelFilterSavingBtnOptions"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="submitFilterSavingBtnOptions"
      />
      <template #content>
        <div class="d-flex flex-column">
          <DxTextBox
            v-model="filterName"
            placeholder="Name Your filter"
          >
            <DxValidator ref="filterNameTextBoxValidator">
              <DxRequiredRule message="Filter Name is required" />
            </DxValidator>
          </DxTextBox>
          <div class="text-danger">
            {{ savingFilterTemplateError }}
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>

<script>
import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup'
import DxTextBox from 'devextreme-vue/text-box'
import DxFilterBuilder from 'devextreme-vue/filter-builder'
import DxButton from 'devextreme-vue/button'
import { DxScrollView } from 'devextreme-vue/scroll-view'
import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator'
import { DxPopover } from 'devextreme-vue/popover'
import MyFilters from '@/views/fba-inventory/components/MyFilters.vue'

export default {
  name: 'FilterBuilder',
  components: {
    DxPosition,
    DxPopup,
    DxRequiredRule,
    DxPopover,
    DxValidator,
    DxScrollView,
    DxButton,
    DxFilterBuilder,
    DxTextBox,
    MyFilters,
    DxToolbarItem,
  },
  props: {
    filterBuilderFields: {
      type: Array,
      default: () => [],
    },
    filterTemplatesKey: {
      type: String,
      required: true,
    },
    appliedFilters: {
      type: Array,
      default: () => [],
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonWidth: {
      type: String,
      default: '36',
    },
  },
  data() {
    return {
      isPopoverVisible: false,
      filters: [],
      debounce: {
        delay: 800,
        timer: undefined,
      },
      isFilterBuilderVisible: false,
      applyFiltersButtonOptions: {
        text: 'Apply',
        onClick: this.onFilterApplyClick,
        cssClass: 'dx-btn-xs',
        stylingMode: 'contained',
        elementAttr: {
          class: 'dx-btn-xs',
        },
      },
      clearFiltersButtonOptions: {
        text: 'Clear',
        onClick: this.onFilterClearClick,
        cssClass: 'dx-btn-xs',
        stylingMode: 'contained',
        elementAttr: {
          class: 'dx-btn-xs',
        },
      },
      templatesBtnOptions: {
        text: 'Templates',
        onClick: this.onTemplatesBtnClick,
        stylingMode: 'contained',
        elementAttr: {
          id: 'filter-templates-btn',
          class: 'dx-btn-xs',
        },
      },
      saveFilterBtnOptions: {
        text: 'Save Active Filters',
        icon: 'save',
        stylingMode: 'text',
        onClick: this.onSaveTemplateBtnClick,
        elementAttr: {
          class: 'dx-btn-xs',
        },
      },
      isSavingFilterPopupVisible: false,

      filterName: null,
      filterTemplates: [],
      cancelFilterSavingBtnOptions: {
        text: 'Cancel',
        stylingMode: 'outlined',
        onClick: this.onCancelSavingFilterTemplateBtnClick,
      },
      submitFilterSavingBtnOptions: {
        text: 'Save',
        stylingMode: 'contained',
        onClick: this.onSubmitFilterTemplateBtnClick,
      },
      savingFilterTemplateError: null,
    }
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
    isCurrentBreakPointMd() {
      return this.currentBreakPoint === 'md'
    },
    marketplaces() {
      return this.$store.getters['app/availableMarketplaceOptions']
    },
    popupWidth() {
      if (this.isCurrentBreakPointXs) {
        return '99vw'
      }

      return 560
    },
    popupHeight() {
      if (this.isCurrentBreakPointXs) {
        return '40vh'
      }
      return 'auto'
    },
    savingFilterPopupWidth() {
      if (this.isCurrentBreakPointXs) {
        return '90vw'
      }
      return 300
    },
    popupPosition() {
      if (this.isCurrentBreakPointMd) {
        return {
          at: 'center bottom',
          my: 'center top',
        }
      }
      if (this.isCurrentBreakPointXs) {
        return {
          at: 'left bottom',
          my: 'left top',
          offset: '-25 0',
        }
      }
      return {
        at: 'right bottom',
        my: 'right top',
        offset: undefined,
      }
    },
    popoverPosition() {
      return {
        at: 'left bottom',
        my: 'left top',
      }
    },
    isFilterApplied() {
      return this.filters && this.filters.length ? 'contained' : 'outlined'
    },
  },
  mounted() {
    this.filters = JSON.parse(JSON.stringify(this.appliedFilters))
    this.$store.dispatch('user/getSettings', this.filterTemplatesKey).then(data => {
      this.filterTemplates = data.items ?? []
    }).catch(() => {
      this.filterTemplates = []
    })
  },
  methods: {
    onFilterApplyClick() {
      this.isFilterBuilderVisible = false
      this.$emit('on-filter-changed', this.filters)
    },
    onFilterClearClick() {
      this.$refs.dxFilterBuilder.instance.option('value', null)
      this.$emit('on-filter-changed', this.filters)
    },
    onFilterBuilderButtonClick() {
      this.isFilterBuilderVisible = !this.isFilterBuilderVisible
    },
    onFilterBuilderValueChange({ value }) {
      this.filters = value
    },
    onTemplatesBtnClick() {
      this.isPopoverVisible = true
    },
    onSaveTemplateBtnClick() {
      if (this.filters) {
        this.isPopoverVisible = false
        this.isFilterBuilderVisible = false
        this.isSavingFilterPopupVisible = true
      }
    },
    onFilterTemplateClick({ filter }) {
      this.isPopoverVisible = false
      this.filters = filter
      this.$emit('on-filter-changed', this.filters)
    },
    onFilterTemplateDelete({ itemIndex }) {
      this.filterTemplates.splice(itemIndex, 1)
      const payload = {
        index: this.filterTemplatesKey,
        payload: { items: this.filterTemplates },
      }
      this.$store.dispatch('user/updateSettings', payload).then(() => {
        this.isSavingFilterPopupVisible = false
      }).catch(() => {
        this.savingFilterTemplateError = 'Server error. Please try later.'
      })
    },
    onCancelSavingFilterTemplateBtnClick() {
      this.savingFilterTemplateError = null
      this.isSavingFilterPopupVisible = false
    },
    onSubmitFilterTemplateBtnClick() {
      if (this.$refs.filterNameTextBoxValidator.instance.validate().isValid) {
        this.filterTemplates.push({
          title: this.filterName,
          filter: this.filters,
        })
        const payload = {
          index: this.filterTemplatesKey,
          payload: { items: this.filterTemplates },
        }
        this.$store.dispatch('user/updateSettings', payload).then(() => {
          this.isSavingFilterPopupVisible = false
        }).catch(() => {
          this.savingFilterTemplateError = 'Server error. Please try later.'
        })
      }
    },
  },
}
</script>

<style scoped>
    ::v-deep .dx-icon-filter::before {
      font-size: 1rem;
    }
</style>
