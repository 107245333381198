<template>
  <b-modal
    v-model="isVisible"
    size="lg"
    scrollable
    title="Cost Settings History"
    hide-footer
  >
    <div
      style="height: 80vh"
      class="d-flex flex-column"
    >
      <DxDataGrid
        v-if="isVisible"
        id="history-grid"
        ref="gridView"
        class="sxr-grid regular-text-color"
        width="100%"
        height="100%"
        :data-source="datasource"
        :show-borders="false"
        :show-row-lines="true"
        :show-column-lines="true"
        :remote-operations="true"
        :word-wrap-enabled="true"
        :no-data-text="noResultsFoundMessage"
        :column-auto-width="true"
        :allow-column-reordering="true"
        column-resizing-mode="widget"
      >
        <DxHeaderFilter
          :visible="false"
          :hide-select-all-on-search="false"
        />
        <DxPaging :page-size="25" />
        <DxScrolling
          mode="virtual"
        />
        <DxColumn
          data-field="start_date"
          caption="Date Range"
          cell-template="dateRangeCell"
          :allow-sorting="false"
          :visible="true"
          width="140"
        />
        <DxColumn
          data-field="field"
          caption="Field Name"
          :allow-sorting="false"
          cell-template="fieldCell"
        />
        <DxColumn
          data-field="old_value"
          caption="Old Value"
          width="140"
          :allow-sorting="false"
        />
        <DxColumn
          data-field="new_value"
          caption="New Value"
          width="140"
          :allow-sorting="false"
        />
        <DxColumn
          data-field="created_at"
          caption="Updated At"
          data-type="datetime"
          width="120"
          :allow-sorting="false"
        />
        <template #fieldCell="{ data }">
          {{ getFieldName(data.data.field) }}
        </template>
        <template #dateRangeCell="{ data }">
          <div
            v-if="data.data.start_date || data.data.end_date"
            class="flex flex-column text-center"
          >
            <div v-if="data.data.start_date">
              {{ formatDate(data.data.start_date) }}
            </div>
            <div v-else>
              First Sale Ever
            </div>
            <div v-if="data.data.end_date">
              {{ formatDate(data.data.end_date) }}
            </div>
            <div v-else>
              Most Recent Sale
            </div>
          </div>
          <div
            v-else
            class="text-center"
          >
            All Time
          </div>
        </template>
      </DxDataGrid>
    </div>
  </b-modal>
</template>

<script>
import {
  DxDataGrid, DxColumn, DxHeaderFilter, DxPaging, DxScrolling,
} from 'devextreme-vue/data-grid'
import moment from 'moment/moment'
import ProductsCostHistoryDatasource from '@/data/product-cost-history.datasource'

export default {
  name: 'HistoryModal',
  components: {
    DxScrolling, DxPaging, DxHeaderFilter, DxDataGrid, DxColumn,
  },
  props: {
    visible: {
      type: Boolean,
    },
    productHistoryId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      noResultsFoundMessage: 'No Result Found',
    }
  },
  computed: {
    datasource() {
      return ProductsCostHistoryDatasource.getInstance(this.$store, {
        product_id: this.productHistoryId,
      })
    },
    isVisible: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).utc().format('MMM D, YYYY')
    },
    getFieldName(field) {
      const fields = {
        fulfilment_by: 'Fulfilment By',
        sales_vat_tax: 'Sales/VAT tax',
        sales_vat_tax_enabled: 'Sales/VAT tax Enabled',
        offset_vat_on_amazon_fee_rate: 'Offset VAT on fees',
        offset_vat_on_amazon_fee_rate_enabled: 'Offset VAT on fees Enabled',
        manufacturing_cost: 'Manufacturing',
        settings: 'Cost Settings',
        inspection_cost: 'Inspection',
        region_shipping_cost: 'Region Shipping',
        import_tax: 'Import Tax',
        other_cost: 'Other Product Costs',
        local_shipping_cost: 'Shipping to Amz',
        fbm_shipping: 'FBM Shipping per unit',
        startDate: 'Start Date',
        endDate: 'End Date',
      }

      return fields[field] || field
    },
  },
}
</script>

<style scoped>

</style>
