<template>
  <div>
    <DataGrid
      ref="dataGrid"
      @on-view-mode-changed="onViewModeChanged"
      @on-edit-click="onEditClick"
      @on-history-click="onHistoryClick"
      @on-delete-click="onDeleteClick"
      @on-import-click="onImportClick"
      @on-import-history-click="onImportHistoryClick"
      @on-download-example-file-click="onDownloadExampleFileClick"
    />
    <EditModal
      v-if="showEdit"
      v-model="showEdit"
      :visible="showEdit"
      :is-loading="isSaving"
      :advanced-view="isAdvancedView"
      :product="product"
      :settings-id="settingsId"
      @on-save="onSave"
    />
    <HistoryModal
      v-if="showHistory"
      v-model="showHistory"
      :product-history-id="productHistoryId"
      :visible="showHistory"
    />
    <ImportHistoryModal
      v-model="showImportHistory"
      :visible="showImportHistory"
    />
    <FileMappingModal
      v-model="showFileMappingModal"
      :visible="showFileMappingModal"
      :mapping-options="mappingOptions"
      :advanced-view="isAdvancedView"
      @process-file="onProcessFile"
    />
    <div class="d-none">
      <form ref="uploadFileForm">
        <input
          id="file_input"
          ref="fileInput"
          type="file"
          accept=".csv"
          @change="onFileChange"
        >
      </form>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import DataGrid from '@/views/settings/products/components/DataGrid.vue'
import EditModal from '@/views/settings/products/components/EditModal.vue'
import HistoryModal from '@/views/settings/products/components/HistoryModal.vue'
import ImportHistoryModal from '@/views/settings/products/components/ImportHistoryModal.vue'
import FileMappingModal from '@/views/settings/products/components/FileMappingModal.vue'
import { apiBaseUrl } from '@/utils/utils'

export default {
  name: 'ProductsConfiguration',
  components: {
    FileMappingModal,
    HistoryModal,
    EditModal,
    DataGrid,
    ImportHistoryModal,
  },
  data() {
    return {
      product: {},
      showEdit: false,
      showHistory: false,
      showImportHistory: false,
      showFileMappingModal: false,
      isSaving: false,
      isAdvancedView: null,

      settingsId: null,
      productHistoryId: null,
      mappingOptions: [],
    }
  },
  mounted() {
    const value = localStorage.getItem('isAdvancedView')
    this.isAdvancedView = value === 'true'
  },
  methods: {
    onEditClick({ data }) {
      this.product = data
      this.settingsId = data.settings_id
      this.showEdit = true
    },
    onHistoryClick({ data }) {
      this.showHistory = true
      this.productHistoryId = data.historyId
    },
    onDeleteClick({ data }) {
      this.$store.dispatch('product/deleteCostsSettings', { id: data.settings_id })
        .then(() => {
          this.$refs.dataGrid.refresh()
        })
        .catch(e => {
          throw e
        })
    },
    onViewModeChanged(value) {
      this.isAdvancedView = value
    },
    onSave(data) {
      this.isSaving = true
      let requestParam
      if (data.isAdvancedView) {
        requestParam = {
          asin: data.asin,
          sku: data.sku,
          marketplace: data.marketplace,
          start_date: data.startDate ? moment(data.startDate).format('YYYY-MM-DD') : undefined,
          end_date: data.endDate ? moment(data.endDate).format('YYYY-MM-DD') : undefined,
          sales_vat_tax_enabled: data.salesVatTaxEnabled,
          sales_vat_tax: data.salesVatTax,
          offset_vat_on_amazon_fee_rate_enabled: data.offsetVatOnFeesEnabled,
          offset_vat_on_amazon_fee_rate: data.offsetVatOnFees,
          manufacturing: data.manufacturing,
          inspection: data.inspection,
          region_shipping: data.regionShipping,
          import_tax: data.importTax,
          other_cost: data.otherProductCosts,
          local_shipping: data.fulfilmentBy === 'FBA' ? data.shippingToAmazon : 0,
          fbm_shipping: data.fulfilmentBy === 'FBM' ? data.fbmShippingPerUnit : 0,
          fulfilment_by: data.fulfilmentBy,
          other_skus: data.otherSkus,
        }
      } else {
        requestParam = {
          asin: data.asin,
          sku: data.sku,
          marketplace: data.marketplace,
          sales_vat_tax_enabled: false,
          sales_vat_tax: 0,
          offset_vat_on_amazon_fee_rate_enabled: false,
          offset_vat_on_amazon_fee_rate: 0,
          manufacturing: 0,
          inspection: 0,
          region_shipping: 0,
          import_tax: 0,
          other_cost: data.otherProductCosts,
          local_shipping: 0,
          fbm_shipping: 0,
          fulfilment_by: data.fulfilmentBy,
          other_skus: data.otherSkus,
        }
      }
      if (data.settingsId) {
        requestParam.settings_id = data.settingsId
        this.$store.dispatch('product/updateCostsSettings', requestParam)
          .then(() => {
            this.showEdit = false
            this.$refs.dataGrid.refresh()
          })
          .catch(e => {
            throw e
          }).finally(() => {
            this.isSaving = false
          })
      } else {
        this.$store.dispatch('product/createCostsSettings', requestParam)
          .then(() => {
            this.showEdit = false
            this.$refs.dataGrid.refresh()
          })
          .catch(() => {
            this.$bvToast.toast('Something went wrong. Please try again.', {
              title: 'Error',
              toaster: 'b-toaster-top-center',
              variant: 'danger',
              solid: true,
              appendToast: false,
            })
          }).finally(() => {
            this.isSaving = false
          })
      }
    },
    onImportHistoryClick() {
      this.showImportHistory = true
    },
    onImportClick() {
      this.$refs.fileInput.click()
    },
    onProcessFile(payload) {
      this.$store.dispatch('inventory/importSettings', { ...payload, file_id: this.fileId }).then(() => {
        this.showFileMappingModal = false
        this.$bvToast.toast('The file was successfully uploaded. Data will be processed in a few minutes.', {
          title: 'Success',
          toaster: 'b-toaster-top-center',
          variant: 'success',
          solid: true,
          appendToast: false,
        })
      }).catch(() => {
        this.$bvToast.toast('Something went wrong. Please try again.', {
          title: 'Error',
          toaster: 'b-toaster-top-center',
          variant: 'danger',
          solid: true,
          appendToast: false,
        })
      })
    },
    onFileChange() {
      this.isUploading = true
      this.$store.dispatch('files/upload', this.$refs.fileInput.files[0]).then(fileData => {
        this.fileId = fileData.file_id
        this.$store.dispatch('inventory/getFirstRowInventorySettingsFile', fileData).then(headerRow => {
          this.mappingOptions = headerRow.map(item => ({ id: item, title: item }))
          this.$nextTick(() => {
            this.showFileMappingModal = true
          })
        })
      }).finally(() => {
        this.isUploading = false
        this.$refs.uploadFileForm.reset()
      })
    },
    onDownloadExampleFileClick() {
      const baseUrl = apiBaseUrl()
      const link = document.createElement('a')
      if (this.isAdvancedView) {
        link.href = `${baseUrl}/inventory/download-import-settings-file-example?advanced=true`
      } else {
        link.href = `${baseUrl}/inventory/download-import-settings-file-example`
      }
      link.click()
    },
  },

}
</script>
