const filterOperations = {
  string: ['contains', 'startswith', 'endswith', '=', '<>'],
  tags: ['contains', 'startswith', 'endswith'],
  numeric: ['=', '<>', '<', '>', '<=', '>=', 'between'],
}
// eslint-disable-next-line import/prefer-default-export
export const fields = [
  {
    caption: 'ASIN',
    width: 50,
    dataField: 'asin',
    dataType: 'string',
    filterOperations: filterOperations.string,
  },
  {
    caption: 'SKU',
    width: 50,
    dataField: 'sku',
    dataType: 'string',
    filterOperations: filterOperations.string,
  },
  {
    caption: 'Product Name',
    width: 50,
    dataField: 'product_name',
    dataType: 'string',
    filterOperations: filterOperations.string,
  },
  {
    caption: 'Cost',
    width: 50,
    dataField: 'price',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Other Cost',
    width: 50,
    dataField: 'other_cost',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Import Taxes',
    width: 50,
    dataField: 'import_taxes_amount',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Local Shipping',
    width: 50,
    dataField: 'local_shipping_amount',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Region Shipping',
    width: 50,
    dataField: 'region_shipping_amount',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Inspection',
    width: 50,
    dataField: 'inspection_amount',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Manufacturing',
    width: 50,
    dataField: 'manufacturing_cost',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
]
