import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'

export default class {
  static getInstance(store) {
    return new DataSource({
      store: new CustomStore({
        load: options => {
          const payload = {}
          payload.take = options.take
          payload.skip = options.skip ?? 0

          if (options.filter) {
            if (Array.isArray(options.filter[0])) {
              // eslint-disable-next-line no-restricted-syntax
              for (const filter of options.filter) {
                if (Array.isArray(filter)) {
                  // eslint-disable-next-line no-unused-vars
                  const [field, operation, value] = filter
                  payload[field] = value
                }
              }
            }
          }

          if (options.sort && options.sort.length) {
            const { selector } = options.sort[0]
            const sortMapping = {
              sellers: 'sellers',
              asin: 'asin',
              new_last_24_hours: 'new_last_24_hours',
              new_last_30_days: 'new_last_30_days',
            }
            payload.sort_by = sortMapping[selector] ?? 'sellers'
            payload.sort_direction = options.sort[0].desc ? 'DESC' : 'ASC'
          }

          return store.dispatch('inventory/listImportSettingsRequest', {}).then(data => {
            if (!data.items) {
              return []
            }

            return {
              data: data.items || [],
              totalCount: (data.items || []).length,
            }
          })
        },
      }),
      paginate: true,
      pageSize: 25,
    })
  }
}
