<template>
  <div class="tw-flex tw-flex-col lg:tw-flex-row md:tw-flex-row tw-gap-2">
    <template v-if="isCurrentBreakPointXs">
      <div class="tw-flex tw-flex-col tw-gap-3">
        <div class="tw-flex tw-gap-2">
          <DxTextBox
            v-model="search"
            class="tw-flex-1 tw-w-full"
            placeholder="Enter ASIN, SKU or keyword"
            :on-key-up="onFilterChange"
            value-change-event="keyup"
          />
          <DxButton
            icon="filter"
            type="default"
            width="35"
            height="35"
            :styling-mode="expandFilters ? 'contained' : 'outlined'"
            @click="onSearchButtonClick"
          />
        </div>
        <div
          class="tw-flex tw-flex-col tw-gap-3"
          :class="{'tw-hidden': !expandFilters}"
        >
          <DxTagBox
            v-model="marketplaces"
            :value="marketplaces"
            class="tw-flex-1 tw-w-full"
            :data-source="marketplaceOptions"
            :search-enabled="true"
            :max-displayed-tags="2"
            :show-multi-tag-only="false"
            display-expr="title"
            value-expr="id"
            placeholder="All Marketplaces"
            item-template="item"
            :on-item-click="onFilterChange"
          >
            <template #item="{ data }">
              <div class="d-flex flex-row justify-content-between align-items-center">
                <div class="flex-1">
                  {{ data.title }}
                </div>
                <div>
                  <CountryFlag :country="data.country.toLowerCase()" />
                </div>
              </div>
            </template>
          </DxTagBox>
          <FilterBuilder
            button-text="Advanced Filters"
            button-width="100%"
            :filter-builder-fields="filterBuilderFields"
            filter-templates-key="products-cost-settings-filters"
            @on-filter-changed="onFilterBuilderValueChange"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <DxTextBox
        v-model="search"
        class="tw-flex-1 tw-w-full"
        placeholder="Enter ASIN, SKU or keyword"
        :on-key-up="onFilterChange"
        value-change-event="keyup"
      />
      <DxTagBox
        v-model="marketplaces"
        :value="marketplaces"
        class="tw-flex-1 tw-w-full"
        :data-source="marketplaceOptions"
        :search-enabled="true"
        :max-displayed-tags="2"
        :show-multi-tag-only="false"
        display-expr="title"
        value-expr="id"
        placeholder="All Marketplaces"
        item-template="item"
        :on-item-click="onFilterChange"
      >
        <template #item="{ data }">
          <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="flex-1">
              {{ data.title }}
            </div>
            <div>
              <CountryFlag :country="data.country.toLowerCase()" />
            </div>
          </div>
        </template>
      </DxTagBox>
      <div class="tw-flex tw-flex-row tw-flex-1 tw-gap-2 tw-w-full tw-items-baseline">
        <FilterBuilder
          :filter-builder-fields="filterBuilderFields"
          filter-templates-key="products-cost-settings-filters"
          @on-filter-changed="onFilterBuilderValueChange"
        />
        <DxCheckBox
          v-model="showOnlyActive"
          text="Only Active"
          :value="showOnlyActive"
          :on-value-changed="onFilterChange"
        />
        <DxCheckBox
          v-if="isCurrentBreakPointXs"
          text="Advanced View"
          :value="isAdvancedView"
          :on-value-changed="onAdvancedViewChange"
        />
      </div>
    </template>
  </div>
</template>

<script>
import DxTextBox from 'devextreme-vue/text-box'
import DxTagBox from 'devextreme-vue/tag-box'
import CountryFlag from 'vue-country-flag'
import { DxCheckBox } from 'devextreme-vue/check-box'
import DxButton from 'devextreme-vue/button'
import FilterBuilder from '@/components/filterBuilder/FilterBuilder.vue'
import { fields as filterBuilderFields } from '@/views/settings/products/filters/conf'

export default {
  name: 'Filters',
  components: {
    DxButton,
    DxCheckBox,
    FilterBuilder,
    DxTextBox,
    DxTagBox,
    CountryFlag,
  },
  props: {
    isAdvancedView: {
      type: Boolean,
      default: false,
    },
    expandFilters: {
      type: Boolean,
      default: false,
    },
    quickFilter: {
      type: Object,
      default: null,
    },
    builderValue: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      search: null,
      builder: null,
      showOnlyActive: true,
      marketplaces: [],
      debounce: null,
      filterBuilderFields,
    }
  },
  computed: {
    marketplaceOptions() {
      return this.$store.getters['app/availableMarketplaceOptions']
    },
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
  },
  mounted() {
    this.search = this.quickFilter?.search
    this.marketplaces = this.quickFilter?.marketplaces
    this.showOnlyActive = this.quickFilter?.showOnlyActive ?? true
    this.builder = this.builderValue
  },
  methods: {
    onAdvancedViewChange({ value }) {
      this.$emit('on-advanced-view-change', { value })
    },
    onFilterChange() {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      this.debounce = setTimeout(() => {
        this.$emit('on-update', JSON.parse(JSON.stringify({
          search: this.search,
          marketplaces: this.marketplaces,
          showOnlyActive: this.showOnlyActive,
          builder: this.builder,
        })))
      }, 500)
    },
    onFilterBuilderValueChange(value) {
      this.builder = value
      this.$emit('on-update', JSON.parse(JSON.stringify({
        search: this.search,
        marketplaces: this.marketplaces,
        showOnlyActive: this.showOnlyActive,
        builder: value,
      })))
    },
    onSearchButtonClick() {
      this.$emit('on-show-filter-click', !this.expandFilters)
    },
  },
}
</script>

<style lang="scss" scoped>
  .toolbar-filter-panel {
    align-items: baseline;
  }
</style>
