<template>
  <b-modal
    v-model="isVisible"
    size="lg"
    scrollable
    title="Import History"
    hide-footer
  >
    <div
      style="height: 80vh"
      class="d-flex flex-column"
    >
      <DxDataGrid
        v-if="isVisible"
        id="import-history-grid"
        ref="gridView"
        class="sxr-grid regular-text-color"
        width="100%"
        height="100%"
        :data-source="datasource"
        :show-borders="false"
        :show-row-lines="true"
        :show-column-lines="true"
        :remote-operations="true"
        :word-wrap-enabled="true"
        :no-data-text="noResultsFoundMessage"
        :column-auto-width="true"
        :allow-column-reordering="true"
        column-resizing-mode="widget"
      >
        <DxHeaderFilter
          :visible="false"
          :hide-select-all-on-search="false"
        />
        <DxPaging :enabled="false" />
        <DxScrolling
          mode="virtual"
        />

        <DxStateStoring
          :enabled="false"
          type="localStorage"
          storage-key="import-history-grid"
        />
        <DxColumnChooser
          :enabled="true"
          mode="select"
        />
        <DxColumn
          data-field="file_id"
          caption="ID"
          :min-width="340"
          :visible="false"
        />
        <DxColumn
          data-field="title"
          caption="Title"
          :min-width="300"
        />
        <DxColumn
          data-field="status"
          caption="Status"
          :min-width="110"
          alignment="center"
        />
        <DxColumn
          data-field="total_rows"
          caption="Stat"
          :min-width="110"
          alignment="center"
          cell-template="statCell"
        />
        <DxColumn
          data-field="date"
          caption="Uploaded At"
          data-type="datetime"
          :min-width="180"
        />
        <DxColumn
          :show-in-column-chooser="false"
          css-class="fixed-column-cell"
          type="buttons"
          alignment="center"
          width="50"
          cell-template="actionsCellTemplate"
        />
        <template #statCell="{ data }">
          <div
            v-if="data.key.total_rows"
            v-b-tooltip="`Total Rows: ${data.key.total_rows} / Bad Rows: ${data.key.bad_rows}`"
          >
            {{ data.key.total_rows }} / {{ data.key.bad_rows }}
          </div>
        </template>
        <template #actionsCellTemplate="{ data: cell }">
          <DxDropDownButton
            :split-button="false"
            :focus-state-enabled="false"
            :show-arrow-icon="false"
            :use-select-mode="false"
            :items="actions(cell)"
            icon="overflow"
            width="34"
            @item-click="onActionClick"
          />
        </template>
      </DxDataGrid>
    </div>
  </b-modal>
</template>

<script>
import {
  DxDataGrid, DxColumn, DxHeaderFilter, DxPaging, DxScrolling, DxColumnChooser, DxStateStoring,
} from 'devextreme-vue/data-grid'
import moment from 'moment/moment'
import { DxDropDownButton } from 'devextreme-vue/drop-down-button'
import { VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ImportHistoryDatasource from '@/data/import-history.datasource'

export default {
  name: 'ImportHistoryModal',
  components: {
    DxDropDownButton,
    DxStateStoring,
    DxColumnChooser,
    DxScrolling,
    DxPaging,
    DxHeaderFilter,
    DxDataGrid,
    DxColumn,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    visible: {
      type: Boolean,
    },
    productHistoryId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      noResultsFoundMessage: 'No Result Found',
      datasource: ImportHistoryDatasource.getInstance(this.$store),
    }
  },
  computed: {
    isVisible: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).utc().format('MMM D, YYYY')
    },

    actions({ data }) {
      return [
        {
          text: 'Download Imported File',
          title: 'Download Imported File',
          action: 'download-imported-file',
          icon: 'download',
          data: {
            id: data.file_id,
            title: data.title,
          },
        },
        {
          text: 'Download Bad Data File',
          title: 'Download Bad Data File',
          action: 'download-bad-data-file',
          icon: 'download',
          disabled: !data.bad_data_file_id,
          data: {
            id: data.bad_data_file_id,
            title: `(Bad Data)${data.title}`,
          },
        },
      ]
    },
    onFileDownloadClick(fileId, fileTitle) {
      this.$store.dispatch('files/download', { file_id: fileId }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileTitle)
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    onActionClick({ itemData }) {
      switch (itemData.action) {
        case 'download-imported-file':
        case 'download-bad-data-file':
          this.onFileDownloadClick(itemData.data.id, itemData.data.title)
          break
        default:
          break
      }
    },
  },
}
</script>

<style scoped>

</style>
