import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'

export default class {
  static getInstance(store, params) {
    return new DataSource({
      store: new CustomStore({
        load: options => {
          const payload = { ...params }
          payload.take = options.take
          payload.skip = options.skip ?? 0
          payload.show_only_active = true

          if (options.filter && options.filter.builder) {
            payload.filter = options.filter.builder
          }

          if (options.filter && options.filter.quickFilter) {
            payload.search = options.filter.quickFilter.search || undefined
            payload.marketplaces = options.filter.quickFilter.marketplaces || undefined
            payload.marketplaces = options.filter.quickFilter.marketplaces || undefined
            payload.show_only_active = options.filter.quickFilter.showOnlyActive || false
          }

          if (options.sort && options.sort.length) {
            const { selector } = options.sort[0]
            const sortMapping = {
              asin: 'asin',
              sku: 'sku',
              product_name: 'product_name',
              cost: 'cost',
              total_cost: 'total_cost',
              marketplace: 'marketplace',
              currency: 'currency',
              date_range: 'date_range',
              manufacturing: 'manufacturing_cost',
              inspection: 'inspection_cost',
              region_shipping: 'region_shipping_cost',
              local_shipping: 'local_shipping_cost',
              import_tax: 'import_tax',
              other_cost: 'other_cost',
            }
            payload.sort_by = sortMapping[selector] ?? 'asin'
            payload.sort_direction = options.sort[0].desc ? 'DESC' : 'ASC'
          }

          if (options.isLoadingAll) {
            payload.export = true
          }

          return store.dispatch('product/fetchProductsConfiguration', payload).then(result => {
            if (!result.items) {
              return []
            }
            return {
              data: result.items,
              totalCount: result.total_items,
            }
          })
        },
        update: (key, values) => {
          const requestParam = {
            asin: key.asin,
            sku: key.sku,
            marketplace: key.marketplace.marketplace_id,
            start_date: key.start_date,
            end_date: key.end_date,
            cost: key.cost,
            other_cost: key.other_cost,
            sales_vat_tax_enabled: key.sales_vat_tax_enabled,
            sales_vat_tax: key.sales_vat_tax,
            offset_vat_on_amazon_fee_rate_enabled: key.offset_vat_on_amazon_fee_rate_enabled,
            offset_vat_on_amazon_fee_rate: key.offset_vat_on_amazon_fee_rate,
            manufacturing: key.manufacturing,
            inspection: key.inspection,
            region_shipping: key.region_shipping,
            import_tax: key.import_tax,
            local_shipping: key.fulfilment_by === 'FBA' ? key.local_shipping : 0,
            fbm_shipping: key.fulfilment_by === 'FBM' ? key.fbm_shipping : 0,
            fulfilment_by: key.fulfilment_by || 'FBA',
          }

          if (values && typeof values.total_cost !== 'undefined') {
            requestParam.other_cost = values.total_cost
            requestParam.cost = 0
            requestParam.manufacturing = 0
            requestParam.inspection = 0
            requestParam.region_shipping = 0
            requestParam.import_tax = 0
            requestParam.local_shipping = 0
            requestParam.fbm_shipping = 0
          }

          if (key.settings_id) {
            requestParam.settings_id = key.settings_id
            return store.dispatch('product/updateCostsSettings', { ...requestParam, ...values })
              .then(() => {
              })
              .catch(e => {
                throw e
              })
          }

          return store.dispatch('product/createCostsSettings', { ...requestParam, ...values })
            .then(() => {
            })
            .catch(e => {
              throw e
            })
        },
      }),
      paginate: true,
      pageSize: 25,
    })
  }
}
