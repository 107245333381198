<template>
  <DxDataGrid
    id="products-configuration"
    ref="gridView"
    class="sxr-grid"
    :class="{ 'filter-panel-expanded': expandFilters }"
    width="100%"
    height="100%"
    :data-source="datasource"
    :show-borders="false"
    :show-row-lines="true"
    :show-column-lines="true"
    :remote-operations="true"
    :word-wrap-enabled="true"
    :no-data-text="noResultsFoundMessage"
    :column-auto-width="true"
    :allow-column-reordering="true"
    :allow-column-resizing="!isCurrentBreakPointXs"
    column-resizing-mode="widget"
    @exporting="onExporting"
    @exported="onExported"
  >
    <DxEditing
      :allow-updating="true"
      mode="cell"
    />
    <DxToolbar>
      <DxItem
        location="before"
        template="filterPanelTemplate"
      />
      <DxItem
        v-if="!isCurrentBreakPointXs"
        location="after"
        template="viewModeTemplate"
      />
      <DxItem
        widget="dxButton"
        show-text="inMenu"
        locate-in-menu="always"
        location="after"
        :options="importButtonOptions"
      />
      <DxItem
        widget="dxButton"
        show-text="inMenu"
        locate-in-menu="always"
        location="after"
        :options="importHistoryButtonOptions"
      />
      <DxItem
        widget="dxButton"
        show-text="inMenu"
        locate-in-menu="always"
        location="after"
        :options="downloadExampleButtonOptions"
      />
      <DxItem
        name="exportButton"
        location="after"
        locate-in-menu="always"
      />
      <DxItem
        name="columnChooserButton"
        location="after"
      />

    </DxToolbar>
    <DxHeaderFilter
      :visible="false"
      :hide-select-all-on-search="false"
    />
    <DxPaging :page-size="25" />
    <DxScrolling
      mode="virtual"
    />
    <DxStateStoring
      :enabled="false"
      type="localStorage"
      :storage-key="gridStorageStateKey"
    />
    <DxExport
      :enabled="true"
      :formats="['csv', 'xlsx']"
      :texts="{
        exportAll: 'Export to {0}'
      }"
    />
    <DxColumnChooser
      :enabled="true"
      mode="select"
    />
    <DxColumn
      v-if="!isCurrentBreakPointXs"
      :allow-exporting="false"
      :allow-editing="false"
      :show-in-column-chooser="false"
      :allow-hiding="false"
      data-field="image"
      caption=""
      data-type="string"
      :allow-resizing="false"
      :allow-sorting="false"
      :fixed="true"
      css-class="fixed-column-cell"
      fixed-position="left"
      :min-width="60"
      :max-width="60"
      :width="60"
      cell-template="imageCell"
    />

    <DxColumn
      v-if="isCurrentBreakPointXs"
      :allow-exporting="false"
      :allow-editing="false"
      :show-in-column-chooser="false"
      :allow-hiding="false"
      data-field="image"
      caption="ASIN/SKU"
      data-type="string"
      :allow-resizing="false"
      :fixed="true"
      css-class="fixed-column-cell"
      fixed-position="left"
      :min-width="140"
      :max-width="140"
      :width="140"
      cell-template="imageWithASINandSKUCell"
    />
    <DxColumn
      v-if="!isCurrentBreakPointXs"
      :allow-editing="false"
      :show-in-column-chooser="false"
      :allow-hiding="false"
      data-field="asin"
      caption="ASIN"
      data-type="string"
      :allow-resizing="false"
      :fixed="true"
      css-class="fixed-column-cell"
      fixed-position="left"
      :min-width="140"
      :max-width="140"
      :width="140"
    />
    <DxColumn
      v-if="!isCurrentBreakPointXs"
      :allow-editing="false"
      :show-in-column-chooser="false"
      :allow-hiding="false"
      data-field="sku"
      caption="SKU"
      data-type="string"
      :allow-resizing="false"
      :fixed="true"
      css-class="fixed-column-cell"
      fixed-position="left"
      :min-width="180"
      :max-width="180"
      :width="180"
    />
    <DxColumn
      :allow-editing="false"
      :show-in-column-chooser="false"
      data-field="marketplace.marketplace_id"
      caption="Marketplace"
      data-type="string"
      :visible="false"
      :min-width="100"
    />
    <DxColumn
      :allow-editing="false"
      :show-in-column-chooser="false"
      data-field="start_date"
      caption="Start Date"
      data-type="string"
      :visible="false"
      :min-width="100"
    />
    <DxColumn
      :allow-editing="false"
      :show-in-column-chooser="false"
      data-field="end_date"
      caption="End Date"
      data-type="string"
      :visible="false"
      :min-width="100"
    />
    <DxColumn
      v-if="!isCurrentBreakPointXs"
      :allow-editing="false"
      :show-in-column-chooser="false"
      :allow-exporting="false"
      :allow-sorting="false"
      data-field="marketplace"
      caption=""
      data-type="string"
      :fixed="true"
      :allow-resizing="false"
      css-class="fixed-column-cell"
      fixed-position="left"
      :min-width="50"
      :max-width="50"
      :width="50"
      alignment="center"
      :render-async="true"
      cell-template="marketplaceCell"
    />
    <DxColumn
      :allow-editing="false"
      data-field="product_name"
      caption="Product Name"
      data-type="string"
      :fixed="!isCurrentBreakPointXs"
      :allow-resizing="false"
      css-class="fixed-column-cell"
      fixed-position="left"
      :min-width="180"
      :visible="false"
    />
    <DxColumn
      :allow-exporting="false"
      :allow-editing="false"
      data-field="date_range"
      caption="Date Range"
      :fixed="!isCurrentBreakPointXs"
      :allow-resizing="false"
      :css-class="!isCurrentBreakPointXs ? 'fixed-column-cell' : ''"
      fixed-position="left"
      :width="140"
      :min-width="140"
      :max-width="140"
      :render-async="true"
      :show-in-column-chooser="false"
      :visible="true"
      cell-template="dateRangeCell"
    />
    <DxColumn
      :allow-exporting="false"
      :allow-editing="false"
      :allow-resizing="false"
      :allow-sorting="false"
      fixed-position="left"
      data-field="is_active"
      data-type="string"
      caption="Active"
      width="80"
      alignment="center"
      :customize-text="customizeTextActiveColumn"
    />
    <DxColumn
      :allow-editing="false"
      :allow-resizing="false"
      :allow-sorting="false"
      fixed-position="left"
      data-field="cost_currency"
      caption="Currency"
      data-type="string"
      width="140"
      alignment="center"
    />
    <DxColumn
      :allow-editing="!isAdvancedView"
      data-field="total_cost"
      caption="Total Cost"
      data-type="number"
      :min-width="140"
      alignment="right"
    >
      <DxNumericRule />
      <DxRangeRule
        :min="0"
        message="Minimum value is 0"
      />
      <DxFormat
        type="fixedPoint"
        :precision="2"
      />
    </DxColumn>
    <DxColumn
      :allow-editing="true"
      data-field="manufacturing"
      caption="Manufacturing"
      data-type="number"
      :min-width="160"
      alignment="right"
      :show-in-column-chooser="isAdvancedView"
      :visible="isAdvancedView"
    >
      <DxNumericRule />
      <DxRangeRule
        :min="0"
        message="Minimum value is 0"
      />
      <DxFormat
        type="fixedPoint"
        :precision="2"
      />
    </DxColumn>
    <DxColumn
      :allow-editing="true"
      data-field="inspection"
      caption="Inspection"
      data-type="number"
      :min-width="150"
      alignment="right"
      :show-in-column-chooser="isAdvancedView"
      :visible="isAdvancedView"
    >
      <DxNumericRule />
      <DxRangeRule
        :min="0"
        message="Minimum value is 0"
      />
      <DxFormat
        type="fixedPoint"
        :precision="2"
      />
    </DxColumn>
    <DxColumn
      :allow-editing="true"
      data-field="region_shipping"
      caption="Region Shipping"
      data-type="number"
      :min-width="170"
      alignment="right"
      :show-in-column-chooser="isAdvancedView"
      :visible="isAdvancedView"
    >
      <DxNumericRule />
      <DxRangeRule
        :min="0"
        message="Minimum value is 0"
      />
      <DxFormat
        type="fixedPoint"
        :precision="2"
      />
    </DxColumn>
    <DxColumn
      :allow-editing="true"
      data-field="local_shipping"
      caption="Local Shipping"
      data-type="number"
      :min-width="170"
      alignment="right"
      :show-in-column-chooser="isAdvancedView"
      :visible="isAdvancedView"
    >
      <DxNumericRule />
      <DxRangeRule
        :min="0"
        message="Minimum value is 0"
      />
      <DxFormat
        type="fixedPoint"
        :precision="2"
      />
    </DxColumn>
    <DxColumn
      :allow-editing="true"
      data-field="import_tax"
      caption="Import Tax"
      data-type="number"
      :min-width="140"
      alignment="right"
      :show-in-column-chooser="isAdvancedView"
      :visible="isAdvancedView"
    >
      <DxNumericRule />
      <DxRangeRule
        :min="0"
        message="Minimum value is 0"
      />
      <DxFormat
        type="fixedPoint"
        :precision="2"
      />
    </DxColumn>
    <DxColumn
      :allow-editing="true"
      data-field="other_cost"
      caption="Other Cost"
      data-type="number"
      :min-width="140"
      alignment="right"
      :show-in-column-chooser="isAdvancedView"
      :visible="isAdvancedView"
    >
      <DxNumericRule />
      <DxRangeRule
        :min="0"
        message="Minimum value is 0"
      />
      <DxFormat
        type="fixedPoint"
        :precision="2"
      />
    </DxColumn>
    <DxColumn
      v-if="!isCurrentBreakPointXs"
      :show-in-column-chooser="false"
      css-class="fixed-column-cell"
      type="buttons"
      :fixed="true"
      fixed-position="right"
      alignment="center"
      width="70"
      cell-template="actionsCellTemplate"
      :render-async="true"
    />
    <DxColumn
      v-if="isCurrentBreakPointXs"
      :show-in-column-chooser="false"
      css-class="fixed-column-cell"
      type="buttons"
      alignment="center"
      width="50"
      cell-template="actionsCellTemplate"
      :render-async="true"
    />
    <template #marketplaceCell="{ data }">
      <CountryFlag
        :key="data.data.marketplace.country_code"
        :country="data.data.marketplace.country_code"
        :title="data.data.marketplace.country"
        :alt="data.data.marketplace.country"
        :size="isCurrentBreakPointXs ? 'small' : undefined"
      />
    </template>
    <template #filterPanelTemplate>
      <Filters
        :is-advanced-view="isAdvancedView"
        :expand-filters="expandFilters"
        :builder-value="builder"
        :quick-filter="quickFilter"
        @on-show-filter-click="onShowFilterClick"
        @on-update="onFilterUpdate"
        @on-advanced-view-change="onViewModeChange"
      />
    </template>
    <template #viewModeTemplate>
      <DxCheckBox
        text="Advanced View"
        :value="isAdvancedView"
        :on-value-changed="onViewModeChange"
      />
    </template>
    <template #actionsCellTemplate="{ data: cell }">
      <DxDropDownButton
        :split-button="false"
        :focus-state-enabled="false"
        :show-arrow-icon="false"
        :use-select-mode="false"
        :items="actions(cell)"
        icon="overflow"
        width="34"
        @item-click="onActionClick"
      />
    </template>
    <template #dateRangeCell="{ data }">
      <div
        v-if="data.data.start_date || data.data.end_date"
        class="flex flex-column text-center"
      >
        <div v-if="data.data.start_date">
          {{ formatDate(data.data.start_date) }}
        </div>
        <div v-else>
          -
        </div>
        <div v-if="data.data.end_date">
          {{ formatDate(data.data.end_date) }}
        </div>
        <div v-else>
          -
        </div>
      </div>
      <div
        v-else
        class="text-center"
      >
        All Time
      </div>
    </template>
    <template #imageCell="{ data }">
      <div class="tw-flex tw-flex-col text-center">
        <ProductImage
          size="33px"
          :src="data.data.image"
          :title="data.data.product_name"
          icon-size="24"
          :is-zoom-available="true"
          :marketplace="data.data.marketplace.marketplace_id"
          :asin="data.data.asin"
          :product-url="data.data.amazon_link"
          attr-class="tw-p-1.5"
        />
      </div>
    </template>
    <template #imageWithASINandSKUCell="{ data }">
      <div class="d-flex flex-column align-items-center">
        <div class="d-flex position-relative">
          <b-avatar
            rounded
            size="50px"
            :src="data.data.image"
            :alt="data.data.sku"
            :title="data.data.product_name"
          >
            <feather-icon
              v-if="isEmpty(data.data.image)"
              size="32"
              icon="ImageIcon"
            />
          </b-avatar>
          <CountryFlag
            :key="data.data.marketplace.country_code"
            class="product-marketplace"
            :country="data.data.marketplace.country_code"
            :title="data.data.marketplace.country"
            :alt="data.data.marketplace.country"
          />
        </div>
        <div
          v-b-tooltip="data.data.asin"
          class="product-text"
        >
          {{ data.data.asin }}
        </div>
        <div
          v-b-tooltip="data.data.sku"
          class="product-text"
        >
          {{ data.data.sku }}
        </div>
      </div>
    </template>
  </DxDataGrid>
</template>

<script>
import {
  DxColumn,
  DxColumnChooser,
  DxDataGrid,
  DxHeaderFilter,
  DxItem,
  DxPaging,
  DxToolbar,
  DxScrolling,
  DxStateStoring,
  DxFormat,
  DxEditing,
  DxNumericRule,
  DxRangeRule, DxExport,
} from 'devextreme-vue/data-grid'
import CountryFlag from 'vue-country-flag'
import { DxDropDownButton } from 'devextreme-vue/drop-down-button'
import { DxCheckBox } from 'devextreme-vue/check-box'
import { BAvatar, VBTooltip } from 'bootstrap-vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver-es'
import productsConfDatasource from '@/data/products-configuration.datasource'
import Filters from '@/views/settings/products/components/Filters.vue'
import ProductImage from '@/components/ProductImage.vue'

export default {
  name: 'DataGrid',
  components: {
    ProductImage,
    DxExport,
    BAvatar,
    Filters,
    DxDataGrid,
    DxColumn,
    DxColumnChooser,
    DxToolbar,
    DxItem,
    DxPaging,
    DxHeaderFilter,
    CountryFlag,
    DxDropDownButton,
    DxScrolling,
    DxStateStoring,
    DxCheckBox,
    DxFormat,
    DxEditing,
    DxNumericRule,
    DxRangeRule,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      noResultsFoundMessage: 'No Result Found',
      datasource: productsConfDatasource.getInstance(this.$store),
      isAdvancedView: false,
      gridStorageStateKey: 'PROD_CONF_GRID_STATE',
      importButtonOptions: {
        icon: 'upload',
        text: 'Import CSV',
        onClick: () => {
          this.$emit('on-import-click')
        },
      },
      importHistoryButtonOptions: {
        icon: 'clock',
        text: 'Import History',
        onClick: () => {
          this.$emit('on-import-history-click')
        },
      },
      downloadExampleButtonOptions: {
        icon: 'download',
        text: 'Download Example File',
        onClick: () => {
          this.$emit('on-download-example-file-click')
        },
      },
      expandFilters: false,
      quickFilter: null,
      builder: null,
    }
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
    gridInstance() {
      return this.$refs.gridView.instance
    },
  },
  mounted() {
    const isAdvancedView = localStorage.getItem('isAdvancedView')
    if (isAdvancedView) {
      this.isAdvancedView = isAdvancedView === 'true'
    } else {
      this.isAdvancedView = false
    }

    if (this.isAdvancedView) {
      this.gridStorageStateKey = 'PROD_CONF_GRID_STATE_ADVANCED'
    }
  },
  methods: {
    actions({ data }) {
      return [
        {
          data,
          text: 'Edit',
          action: 'edit',
          icon: 'edit',
        },
        {
          text: 'History',
          action: 'history',
          icon: 'clock',
          data: {
            asin: data.asin,
            sku: data.sku,
            marketplace: data.marketplace,
            historyId: parseInt(data.history_id, 10),
          },
        },
        {
          data: {
            settings_id: data.settings_id,
          },
          text: 'Delete',
          action: 'delete',
          icon: 'trash',
          disabled: !data.settings_id,
        },
      ]
    },
    onActionClick({ itemData }) {
      switch (itemData.action) {
        case 'edit':
          this.$emit('on-edit-click', itemData)
          break
        case 'history':
          this.$emit('on-history-click', itemData)
          break
        case 'delete':
          this.$emit('on-delete-click', itemData)
          break
        default:
          break
      }
    },
    onFilterUpdate({
      search, marketplaces, builder, showOnlyActive,
    }) {
      let quickFilter

      if (search && search.length) {
        quickFilter = {
          search,
        }
      }
      if (marketplaces && marketplaces.length) {
        if (quickFilter) {
          quickFilter.marketplaces = marketplaces
        } else {
          quickFilter = {
            marketplaces,
          }
        }
      }

      if (quickFilter) {
        quickFilter.showOnlyActive = showOnlyActive
      } else {
        quickFilter = {
          showOnlyActive,
        }
      }

      if (builder || quickFilter) {
        this.quickFilter = quickFilter
        this.builder = builder
        this.gridInstance.filter({
          quickFilter,
          builder,
        })
      } else {
        this.quickFilter = null
        this.builder = null
        this.gridInstance.clearFilter()
      }
    },
    onViewModeChange({ value }) {
      this.$emit('on-view-mode-changed', value)
      this.isAdvancedView = value
      localStorage.removeItem('isAdvancedView')
      localStorage.setItem('isAdvancedView', value)
    },
    isEmpty(value) {
      return !value || value === 'null' || value === 'undefined' || value.trim() === ''
    },
    formatDate(date) {
      return moment(date).utc().format('MMM D, YYYY')
    },
    refresh() {
      this.gridInstance.refresh()
    },
    customizeTextActiveColumn({ value }) {
      return value ? 'Yes' : 'No'
    },
    onExported(e) {
      e.component.columnOption('marketplace.marketplace_id', 'visible', false)
      e.component.columnOption('start_date', 'visible', false)
      e.component.columnOption('end_date', 'visible', false)
      e.component.endUpdate()
    },
    onExporting(e) {
      e.component.beginUpdate()
      e.component.columnOption('marketplace.marketplace_id', 'visible', true)
      e.component.columnOption('start_date', 'visible', true)
      e.component.columnOption('end_date', 'visible', true)

      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Products Cost')

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        const date = new Date()
        if (e.format === 'csv') {
          workbook.csv.writeBuffer().then(buffer => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Products Cost ${date.toLocaleDateString('en-US')}.csv`)
          })
        } else {
          workbook.xlsx.writeBuffer().then(buffer => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Products Cost ${date.toLocaleDateString('en-US')}.xlsx`)
          })
        }
      })
      e.cancel = true
    },
    onShowFilterClick(value) {
      this.expandFilters = value
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/common/grid.scss';
.dark-layout .dx-popup-wrapper > .dx-overlay-content {
  border: 1px solid #404656;
  background: #353d55;
}

#products-configuration {
  .dx-datagrid-revert-tooltip .dx-revert-button {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
  #products-configuration {
    min-height: calc(100dvh - 120px);
    max-height: calc(100dvh - 120px);

    .dx-datagrid-rowsview .dx-row {
      min-height: 51.7px;
    }

    .product-marketplace {
      position: absolute;
      right: -17px;
      bottom: -2px;
    }

    .product-text {
      font-size: 0.8rem;
      text-align: center;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>
